<template>
  <v-card outlined style="height: 100%">
    <card-toolbar
      :graphic="graphic"
      :title="'Resultados de secciones de una evaluación'"
      :helpId="25"
      @showFilters="filtersDialog = true"
      @deleteGraphic="$emit('deleteGraphic', $event)"
    />

    <apexchart
      style="min-height: 200px !important"
      :key="index"
      :height="'90%'"
      :type="graphic.type_chart"
      :options="chartOptions"
      :series="chartSeries"
    />
    <span style="font-size: 10px; position: absolute; right: 5px; bottom: 5px"
      >{{
        new Date(startAt).toLocaleDateString('es-VE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        })
      }}
      -
      {{
        new Date(endAt).toLocaleDateString('es-VE', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC',
        })
      }}</span
    >

    <v-dialog
      v-model="filtersDialog"
      width="auto"
      :max-width="$vuetify.breakpoint.xs ? '90%' : '50%'"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Filtrar: <small class="ml-2">{{ ' ' + graphic.title }}</small>
        </v-card-title>

        <date-filter
          :startAt.sync="startAt"
          :endAt.sync="endAt"
          :periodicity="periodicity"
          :validDate.sync="validDate"
        />

        <v-divider />

        <v-row class="mx-0 graphic-one">
          <v-col cols="12">
            <label>Empresas: </label>
            <multiselect
              v-model="selectedCompanies"
              label="name"
              track-by="id"
              placeholder="Seleccionar"
              open-direction="bottom"
              :options="allowedCompanies"
              :multiple="true"
              :searchable="true"
              :loading="isLoading"
              :internal-search="true"
              :clear-on-select="true"
              :close-on-select="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
            >
              <span slot="noResult"
                >No se encontraron elementos. Considere cambiar la consulta de
                búsqueda.</span
              >
            </multiselect>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="mx-0 graphic-one">
          <v-col cols="12">
            <label>Evaluaciones: </label>
            <multiselect
              v-model="selectedEvaluation"
              label="name"
              track-by="id"
              placeholder="Buscar"
              open-direction="bottom"
              :options="evaluations"
              :allow-empty="false"
              :multiple="false"
              :searchable="true"
              :internal-search="true"
              :clear-on-select="true"
              :close-on-select="true"
              :options-limit="300"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
            >
              <span slot="noResult"
                >No se encontraron elementos. Considere cambiar la consulta de
                búsqueda.</span
              >
            </multiselect>
          </v-col>
        </v-row>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :disabled="!validFilter"
            @click.prevent="getChartData(), (filtersDialog = false)"
          >
            Aplicar filtros
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  import DateFilter from '@/components/Dashboards/Filters/DateFilter'
  import { decryptData } from '@/utils/encryption'
  import { itemsPeriodicity, toLocaleDateString } from '@/utils/helpers'
  import apexChartOptions from '@/utils/apexChartOptions'
  import CardToolbar from '@/components/Dashboards/CardToolbar'
  import { getIntervalDatesByPeriodicity } from '@/utils/dateFunctions'

  const today = new Date()
  export default {
    name: 'Graphic',
    components: { CardToolbar, DateFilter },
    props: {
      graphic: {
        type: Object,
        required: true,
        default: () => {},
      },
      generalStartAt: {
        type: String,
        required: true,
      },
      generalEndAt: {
        type: String,
        required: true,
      },
      generalPeriodicity: {
        type: Number,
        required: true,
      },
      allowedCompanies: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        index: 0,
        isLoading: false,
        validDate: true,
        filtersDialog: false,
        selectedCompanies: [],
        evaluations: [],
        selectedEvaluation: null,
        chartOptions: JSON.parse(JSON.stringify(apexChartOptions)),
        chartSeries: [],
        itemsPeriodicity: itemsPeriodicity,
        periodicity: 3,
        startAt: new Date(today.getFullYear(), today.getMonth() - 1, 1)
          .toISOString()
          .split('T')[0],
        endAt: new Date(today.getFullYear(), today.getMonth(), 0)
          .toISOString()
          .split('T')[0],
      }
    },
    filters: {
      toStatusText(status) {
        if (!status) return ''
        switch (status) {
          case 0:
            return 'Asignadas'
          case 1:
            return 'En curso'
          case 2:
            return 'Completadas'
          case 3:
            return 'Finalizadas'
          default:
            return ''
        }
      },
    },
    created() {
      this.periodicity = this.generalPeriodicity
      this.startAt = this.generalStartAt
      this.endAt = this.generalEndAt
      this.setDefaultOptions()
      this.getEvaluations()
    },
    computed: {
      ...mapGetters(['userCurrentCompany']),
      company: {
        get() {
          return decryptData(this.userCurrentCompany)
        },
        set(newValue) {},
      },
      dateString() {
        return `&startAt=${this.startAt}&endAt=${this.endAt}`
      },
      validFilter() {
        return this.validDate
      },
      companyString() {
        if (this.selectedCompanies.length > 0) {
          let string = ''
          this.selectedCompanies.forEach((company, index) => {
            string = string + `&companies[${index}]=${company.id}`
          })
          return string
        } else {
          return `&company_id=${this.company.id}`
        }
      },
      evaluationString() {
        if (this.selectedEvaluation) {
          return `&evaluation_id=${this.selectedEvaluation.id}`
        } else {
          return ''
        }
      },
    },
    watch: {
      selectedEvaluation(newvalue) {
        this.chartOptions.title.text = newvalue.name
      },
      periodicity(newValue) {
        const interval = this.getIntervalDatesByPeriodicity(today, newValue)
        this.startAt = interval.startAt
        this.endAt = interval.endAt
        this.getEvaluations()
      },
      generalPeriodicity(newValue) {
        this.periodicity = newValue
      },
      generalStartAt(newValue) {
        this.startAt = newValue
      },
      generalEndAt(newValue) {
        this.endAt = newValue
      },
    },
    methods: {
      toLocaleDateString: toLocaleDateString,
      getIntervalDatesByPeriodicity: getIntervalDatesByPeriodicity,
      setDefaultOptions() {
        this.chartOptions.chart.stacked = this.graphic.stacked
      },
      getEvaluations() {
        const queryParams =
          '?limit=5&startAt=' +
          this.startAt +
          '&endAt=' +
          this.endAt +
          '&order=created_at&by=asc'
        this.$axios.get('evaluations' + queryParams).then((response) => {
          this.evaluations = response.data
          if (this.evaluations.length > 0) {
            this.selectedEvaluation = this.evaluations[0]
            this.chartOptions.title.text = this.selectedEvaluation.name
            this.getChartData()
          }
        })
      },
      getChartData() {
        this.$axios
          .get(
            this.graphic.endpoint +
              this.dateString +
              this.companyString +
              this.evaluationString
          )
          .then((response) => {
            if (response.data.length !== 0) {
              this.chartSeries = response.data.series
              this.chartSeries[1].name = 'Resultado Obtenido'
              this.chartSeries[0].name = 'Resultado Objetivo'
              this.chartOptions.xaxis.categories = response.data.labels
            } else {
              this.chartSeries = []
            }
            this.index++
          })
      },
    },
  }
</script>
